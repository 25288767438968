<template>
  <div class="street-info">
    <van-tabs
      color="#1989fa"
      title-active-color="#1989fa"
      v-model="active"
      @click="onClick"
    >
      <van-tab :title="item.title" v-for="(item, index) in vlist" :key="index">
        <div class="list-con">
          <load-list :interFace="interFace">
            <template v-slot:list="props">
              <div
                v-for="(item, index) in props.list"
                :key="index"
                class="list"
              >
                <div class="border">
                    <div class="left">
                        <!-- <img :src="userInfo.avatar" alt="" /> -->
                        <img src="@/assets/images/1.png" alt="">
                    </div>
                    <div class="right">
                        <!-- <div class="up">{{item.username}}</div> -->
                        <div class="up">哈哈</div>
                        <div class="down">2020-07-06</div>
                    </div>
                    <div class="sign">
                        <img v-if="item.tpe==1" src="@/assets/images/to.png" alt="">
                        <img v-if="item.tpe==2" src="@/assets/images/un.png" alt="">
                    </div>
                </div>
                <div class="con">
                    {{ item.title }}
                </div>
                <div class="imgs">
                    <img
                        :src="$IMG_PATH + pitem"
                        alt=""
                        v-for="(pitem, pindex) in item.images"
                        :key="pindex"
                    />
                </div>
              </div>
            </template>
          </load-list>
        </div>
      </van-tab>
    </van-tabs>
     <img class="zxj" @click="getSuggest()" src="@/assets/images/zxj.png" alt="">
  </div>
</template>
<script>
import { getCategory, getCategoryInfo, getInform } from "../api";
import LoadList from "@/components/SearchLoadList";
import Cyberplayer from "../../../components/cyberplayer/index";

export default {
  components: {
    LoadList,
    Cyberplayer,
  },
  data() {
    return {
      active: 0,
      id: 73,
      vlist: [
        {id:73,image:"",title:"全部民意"},
        {id:74,image:"",title:"处理中"},
        {id:75,image:"",title:"已处理"},
      ],
      interFace: {
        api: getInform,
        params: {
          cid: 73,
        },
      },
      articleList: [],
    };
  },
  created() {
    // this.init();
  },
  methods: {
    onClick(name, title) {
      // console.log(name, title);
      this.id = this.vlist[name].id;
      this.$set(this.interFace.params, "cid", this.id);
    },
    toArticleDetail(id) {
      this.$router.push({
        name: "articleDetail",
        params: {
          id: id,
        },
      });
    },
    // init() {
    //   let params = {
    //     id: 62,
    //   };
    //   getCategory(params).then((res) => {
    //     this.vlist = res.data;
    //     console.log(res);
    //     this.id = res.data[0].id;
    //     this.getList();
    //   });
    // },
    getList() {
      let params = {
        cid: this.id,
      };
      getInform(params).then((res) => {
        this.articleList = res.data.items;
      });
    },
    getSuggest(){
      this.$router.push({
          name: "Suggest",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.street-info {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: url("../../../assets/images/mybg.png") left top no-repeat;
  background-position-y: -1rem;
  padding-top: 1.9rem;
  .van-tabs{
      background: #ffffff;
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;
      padding-top:0.2rem;
  }
  .list {
      width: 100%;
      background-color: #fff;
      box-sizing: border-box;
      padding: 0.3rem;
      min-height: 4rem;
      border-radius: 0.16rem;
      margin-top: 0.2rem;
    .border {
      width: 100%;
      height: 1.2rem;
      display: flex;
      align-items: center;
         .left {
            width: 1.2rem;
            height: 1.2rem;
            // margin-top: 1.2rem;
            img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            }
        }
        .right {
            // margin-top: 1.2rem;
            margin-left: 0.3rem;
            .up {
                color: #333333;
                font-size: 0.34rem;
            }
            .down {
                color: #999999;
                font-size: 0.24rem;
                margin-top: 0.3rem;
            }
        }
        .sign{
             margin-left: 3rem;
        }
    }
    .con {
        font-size: 0.3rem;
        color: #333;
        margin-top: 0.5rem;
    }
    .imgs {
        margin-top: 0.2rem;
        img {
          border-radius: 0.1rem;
          width: 2rem;
          height: 1.6rem;
          margin-right: 0.05rem;
          margin-left: 0.05rem;
        }
    }
  }
  .zxj{
        position: fixed;
        right: -0.3rem;
        bottom: 1rem;
    }
  div.list:first-child {
    border-radius: 0.14rem 0.14rem 0 0;
  }
  div.list:last-child {
    border-radius: 0 0 0.14rem 0.14rem;
  }
}
</style>